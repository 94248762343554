@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
    max-width: 1900px;
    margin: 0 auto !important; 
	float: none !important; 
    /* overflow-x: auto; */
    background-color: #F3EEE8
}

h1.stroke {
    /* Prefix required. Even Firefox only supports the -webkit- prefix */
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #b4b4b4;
}

button.stroke {
    /* Prefix required. Even Firefox only supports the -webkit- prefix */
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #b4b4b4;
}
  
button.stroke:hover {
    /* Prefix required. Even Firefox only supports the -webkit- prefix */
    -webkit-text-stroke-width: 0px;
}

span.stroke {
    /* Prefix required. Even Firefox only supports the -webkit- prefix */
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #D71515;
}

.scrollbarhide {
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
}
  
.scrollbarhide::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
}

